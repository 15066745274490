export default {
  general_permissions_tab_subtitle:
    'This section covers general system permissions for this user in this program',
  duet_permissions_tab_subtitle:
    'This section outlines permissions related to Duet',
  snapshot_permissions_tab_subtitle:
    'This section outlines permissions related to Snapshot',
  casper_permissions_tab_subtitle:
    'This section outlines permissions related to Casper',
  analytics_permissions_tab_subtitle:
    'These are the types of content the user can view within Analytics',
  permissions_tab_data_types: 'Data types',
  permissions_tab_data_filters: 'Data filters',
  permissions_tab_data_filters_subtitle:
    'This section outlines which specific subsets of data a user can access',
  permissions_tab_data_filters_no_access: 'No access',
  permissions_tab_data_filters_no_access_description:
    'This user cannot see any records. Any dashboard to which this user has been granted access (via data types) will not be populated with any records at this time. Data access must be defined to allow this user to view records.',
  permissions_tab_data_filters_unrestricted_access: 'Unrestricted access',
  permissions_tab_data_filters_unrestricted_access_description:
    'This user can see all records. Any dashboard that this user has access to (via data types) will be fully populated with all available records. Any new incoming records will automatically be made accessible for this user.',
  analytics_help_text: 'Need Help? Learn more about data types and filters',
  save_general_permissions: 'Save general permissions',
  save_duet_permissions: 'Save Duet permissions',
  save_snapshot_permissions: 'Save Snapshot permissions',
  save_casper_permissions: 'Save Casper permissions',
  save_analytics_permissions: 'Save Analytics permissions',
  general_permissions_title: 'General permissions',
  duet_permissions_title: 'Duet permissions',
  snapshot_permissions_title: 'Snapshot permissions',
  casper_permissions_title: 'Casper permissions',
  analytics_permissions_title: 'Analytics permissions',
  feature_category_title: 'Features',
  data_category_title: 'Data types',
  data_category_subtitle_analytics:
    'These are the types of content the user can view within Analytics.',
  navigation_confirmation_title: 'You have unsaved changes',
  navigation_confirmation_description_line_1:
    'If you navigate away or switch tabs now, your changes will be lost.',
  navigation_confirmation_description_line_2:
    'Do you want to stay and continue editing, or leave without saving?',
  navigation_confirmation_stay: 'Stay',
  navigation_confirmation_leave_without_saving: 'Leave without saving',
  save_permissions_duration:
    'Permission updates may take up to 15 minutes to be reflected across the system.',
  save_permissions_success_toast_message: 'Your changes have been saved.',
  save_permissions_error_toast_message:
    'Something went wrong. Your changes could not be saved. Please try again.',
  no_program_permissions:
    'This user does not have any permissions assigned to this program. Assign permissions to ensure the user can access this program.',
  program_permissions: 'Program permissions',
  unrestricted_access_summary:
    '<b>{{name}}</b> has full access to records belonging to the <b>data type permissions</b> granted above. This user can see records for their authorized data types for:',
  unrestricted_access_summary_description:
    'All <b>Learners</b><br />AND<br />All <b>Learning events</b>',
  data_filter_description:
    'The following filters are used to define granular data access for <b>{{name}}</b>. As filter options are selected, the data filter summary to the left will reflect how these filters work together. To grant this user unrestricted access, uncheck the below options.',
  filter_data_by_learner: "Filter data access by 'Learner' attributes",
  filter_data_by_learning_events:
    "Filter data access by 'Learning event' attributes",
  manage_data_filters: 'Manage filters',
  restricted_access_summary:
    '<b>{{name}}</b> has no access to records belonging to the <b>data type permissions</b> granted above. This user can see <b>no</b> data records. Remove or select filters on the right to give full or limited access.',
  limited_access_summary:
    '<b>{{name}}</b> has limited access to records belonging to the <b>data type permissions</b> granted above. This user can only see records for their authorized data types where:',
  filter_access_warning:
    'Upon saving Analytics permissions, this data filter section will be turned off because there are no current filter selections.',
  confirm_uncheck_filter_title: 'Please confirm',
  confirm_uncheck_filter_body:
    "Are you sure you want to uncheck this section? Doing so will clear all '{{filterType}}' attributes.",
  filter_type_learner: 'Learner',
  filter_type_learning_event: 'Learning event'
};
