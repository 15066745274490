export enum PERMISSIONS {
  MANAGE_USERS = 'manageUsers',
  MANAGE_USERS_V2 = 'manageUsersV2',
  VIEW_DUET_PROFILE = 'viewDuetProfile',
  ACCESS_RESULTS = 'accessApplicantsResults',
  TAKE_DUET = 'takeDuet',
  REVIEW_SNAPSHOT = 'reviewSnapshot',
  MANAGE_SNAPSHOT_REVIEWERS = 'manageSnapshotReviewers',
  BASIC_ACCESS = 'basicAccess',
  PASSTHROUGH = 'passthrough',
  CLINICAL_GATEWAY_EXAMS_VIEWER = 'clinicalGatewayExamsViewer',
  EXAM_VIEWER = 'examViewer',
  COURSE_EVALUATION_VIEWER = 'courseEvaluationViewer',
  RESIDENCY_MATCH_DATA_VIEWER = 'residencyMatchDataViewer',
  MSPE_VIEWER = 'mspeViewer',
  NBME_SUBJECT_EXAMS_VIEWER = 'nbmeSubjectExamsViewer',
  PRECLINICAL_GATEWAY_EXAMS_VIEWER = 'preclinicalGatewayExamsViewer',
  MATRICULATION_DATA_VIEWER = 'matriculationDataViewer',
  GRADES_DATA_VIEWER = 'gradesDataViewer',
  INTERNAL = 'internal',
  STUDENT_ASSESSMENT_VIEWER = 'studentAssessmentViewer',
  VIEW_ACCESS_LOGS = 'viewAccessLogs'
}

export enum APP_NODE_ENV {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production'
}

export enum TWO_FA_METHODS {
  SMS = 'sms',
  CALL = 'call',
  EMAIL = 'email'
}

export const NON_ANALYTICS_PERMISSIONS: PERMISSIONS[] = [
  PERMISSIONS.VIEW_DUET_PROFILE,
  PERMISSIONS.ACCESS_RESULTS,
  PERMISSIONS.TAKE_DUET,
  PERMISSIONS.REVIEW_SNAPSHOT,
  PERMISSIONS.MANAGE_SNAPSHOT_REVIEWERS,
  PERMISSIONS.MANAGE_USERS
];

export const DATA_TYPE_PERMISSIONS: PERMISSIONS[] = [
  PERMISSIONS.BASIC_ACCESS,
  PERMISSIONS.CLINICAL_GATEWAY_EXAMS_VIEWER,
  PERMISSIONS.COURSE_EVALUATION_VIEWER,
  PERMISSIONS.EXAM_VIEWER,
  PERMISSIONS.GRADES_DATA_VIEWER,
  PERMISSIONS.INTERNAL,
  PERMISSIONS.STUDENT_ASSESSMENT_VIEWER,
  PERMISSIONS.MATRICULATION_DATA_VIEWER,
  PERMISSIONS.NBME_SUBJECT_EXAMS_VIEWER,
  PERMISSIONS.PRECLINICAL_GATEWAY_EXAMS_VIEWER,
  PERMISSIONS.RESIDENCY_MATCH_DATA_VIEWER
];

export const ANALYTICS_PERMISSIONS: PERMISSIONS[] = [
  PERMISSIONS.PASSTHROUGH,
  PERMISSIONS.MSPE_VIEWER,
  ...DATA_TYPE_PERMISSIONS
];

export const QLIK_CUSTOM_PROPERTIES_ROLES: PERMISSIONS[] = [
  PERMISSIONS.MANAGE_USERS_V2,
  ...DATA_TYPE_PERMISSIONS
];

export enum QLIK_OBJECT_TYPES {
  FILTER = 'filterpane',
  ACTION_BUTTON = 'action-button',
  HEADER = 'one45header',
  // Qlik images may include the word image, do not use ===, instead use includes
  IMAGE = 'image',
  HISTOGRAM = 'histogram',
  MAP = 'map',
  CONTAINER = 'container',
  BI_IRREGULAR_IMAGE = 'bi-irregular-image',
  KPI = 'kpi',
  HIERARCHY_FILTER = 'hierarchyfilterpane',
  BARCHART = 'barchart',
  BOXPLOT = 'boxplot',
  SHEET = 'sheet'
}

export const KEYBOARD_EVENT_VALUES = {
  SPACE: ' ',
  SPACE_CODE: 'Space',
  ENTER: 'Enter',
  ESC: 'Escape'
};

export enum PERMISSION_GROUPS {
  VISUALIZATION = 'visualization',
  GENERAL = 'general',
  DUET = 'duet',
  SNAPSHOT = 'snapshot'
}

export enum TAB_TYPES {
  HEADING = 'HEADING',
  LINK = 'LINK',
  DROPDOWN = 'DROPDOWN',
  DASHBOARD = 'DASHBOARD'
}

export enum USER_ROLES {
  ADMIN = 'admin',
  SCHOOL_ADMIN = 'school-admin',
  TEAM_MEMBER = 'team-member',
  ARCHIVED = 'archived',
  RATER = 'rater',
  REVIEWER = 'reviewer',
  FORMATIVE = 'formative'
}

export enum QLIK_ERROR_PATTERNS {
  CONNECTION_LOST = 'Connection lost',
  SESSION_EXPIRED = 'Your session'
}

export const ACCEPTED_INSIGHTS_ROLES: USER_ROLES[] = [
  USER_ROLES.ADMIN,
  USER_ROLES.SCHOOL_ADMIN,
  USER_ROLES.TEAM_MEMBER
];

export enum PERMISSION_PRODUCTS {
  CASPER = 'casper',
  DUET = 'duet',
  SNAPSHOT = 'snapshot',
  ANALYTICS = 'analytics'
}

export enum PERMISSION_CATEGORY {
  FEATURE = 'feature',
  DATA = 'data'
}

interface IRoutes {
  [key: string]: {
    titleKey?: string;
    title?: string;
    path: string;
  };
}

export const ROUTES: IRoutes = {
  HOME: {
    titleKey: 'navbar_home',
    path: '/'
  },
  TAKE_DUET: {
    titleKey: 'navbar_take_duet',
    path: '/duet/take-duet'
  },
  VIEW_DUET_PROFILE: {
    titleKey: 'navbar_program_profile',
    path: '/duet/program-profile'
  },
  SNAPSHOT: {
    title: 'Snapshot',
    path: '/snapshot'
  },
  APPLICANTS: {
    titleKey: 'navbar_assessment_results',
    path: '/admissions/applicants'
  },
  FILES: {
    titleKey: 'navbar_download_assessment_results',
    path: '/admissions/files'
  },
  USERS: {
    titleKey: 'navbar_users',
    path: '/settings/users'
  },
  ACCESS_LOGS: {
    titleKey: 'navbar_access_logs',
    path: '/settings/access-logs'
  },
  EDIT_SCHOOL: {
    title: 'Configure School',
    path: '/settings/edit-school'
  },
  CONFIGURE_USERS: {
    title: 'Configure Users',
    path: '/settings/configure-users'
  },
  MANAGE_PERMISSIONS: {
    title: 'Manage Permissions',
    path: '/settings/manage-permissions'
  },
  CONFIGURE_MENU: {
    title: 'Configure Menu',
    path: '/settings/configure-menu'
  },
  CONFIGURE_INTEGRATIONS: {
    title: 'Configure Integrations',
    path: '/settings/configure-integrations'
  },
  CONFIGURE_INTEGRATIONS_SETUP: {
    title: 'Configure Integrations',
    path: '/settings/configure-integrations/setup'
  },
  TERMS_OF_USE: {
    titleKey: 'common_terms_and_conditions',
    path: '/terms'
  },
  SIGN_IN: {
    titleKey: 'signin_page_title',
    path: '/signin'
  },
  RESET_PASSWORD: {
    titleKey: 'reset_password_page_title',
    path: '/reset'
  },
  RESET_PASSWORD_TOKEN: {
    titleKey: 'reset_password_page_title',
    path: '/reset/:token'
  },
  RULES_EDITOR: {
    title: 'Taggings & Embellishments',
    path: '/settings/taggings-embellishments/rules/edit'
  },
  RULES_EDITOR_READ_ONLY: {
    title: 'Taggings & Embellishments',
    path: '/settings/taggings-embellishments/rules/view'
  },
  TAGGINGS_AND_EMBELLISHMENTS: {
    title: 'Taggings & Embellishments',
    path: '/settings/taggings-embellishments'
  },
  MANAGE_USERS: {
    title: 'Manage Users',
    path: '/settings/manage-users'
  },
  EDIT_USER: {
    title: 'Edit User',
    path: '/settings/manage-users/edit'
  },
  TEAM_SUPPORT: {
    path: '/passthrough/team-support'
  },
  NOT_FOUND: {
    title: '404 Not Found',
    path: '/404'
  },
  FORBIDDEN: {
    title: '403 Forbidden',
    path: '/403'
  },
  LOG_OUT: {
    title: 'LOG OUT',
    path: '/logout'
  }
};

export enum GRAPHQL_ERROR_TYPE {
  INVALID = 'invalid',
  EXPIRED = 'expired'
}

export enum GRAPHQL_ERROR_CODE {
  DUPLICATE_EMAIL = 'DUPLICATE_EMAIL',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
}

export enum STORAGE_KEY {
  GLOBAL_TOASTS = 'globalToasts',
  LOGIN_TOKEN = 'loginToken',
  REAUTHENTICATE_TOKEN = 'reauthenticateToken',
  PASSTHROUGH = 'passthrough',
  PASSTHROUGH_SCHOOL_ID = 'passthroughSchoolId',
  PROGRAM_FILTER = 'programFilter',
  PENDO_IP_ADDR = 'pendoIpAddr'
}

export const ERROR_CODES = {
  DUPLICATE_EMAIL: 'DUPLICATE_EMAIL'
};

export enum UPDATE_QLIK_ACTIONS {
  ADD = 'ADD',
  DELETE = 'DELETE',
  REPLACE_ALL = 'REPLACE_ALL'
}

export enum ORIENTATION {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}

export enum GROUPING {
  GROUPED = 'grouped',
  STACKED = 'stacked'
}

export enum DYNAMIC_CLASSES {
  INTERCOM_INITIAL = '.intercom-lightweight-app',
  INTERCOM_OPENED = '#intercom-container'
}

export enum FILE_TYPE {
  PDF = 'PDF',
  XLSX = 'XLSX',
  IMG = 'Image'
}

// From Qlik NxLocalizedErrorCodes documentation.
export enum QLIK_ERROR_CODES {
  LOCERR_CALC_EVAL_CONDITION_FAILED = 7005
}

export const DASHBOARD_RESPONSE_LIMIT = 5;

export const QLIK_ADMIN_DIRECTORY = 'UNIFY';

export enum QLIK_DATA_FILTER_CONSTANTS {
  SHEET_ID = 'hWLgqc',
  LEARNER_FILTERS_TITLE = 'Learner filters'
}

export enum QLIK_DATA_FILTER_FIELDS {
  LEARNER_ID = 'learner_id',
  GRADUATION_YEAR = 'graduation_year',
  LEARNER_CAMPUS = 'campus',
  EVENT_CAMPUS = 'event_campus',
  CATALOG_CODE = 'catalog_code',
  ACADEMIC_YEAR = 'academic_year',
  PHASE = 'phase',
  EVENT_SITE = 'event_site',
  UNIQUE_NAME = 'unique_name'
}

export const ACCESS_LOG_EVENT_MAP = {
  SESSION_START: 'Session created',
  SESSION_END: 'Session ended',
  UPDATED_USER: 'User updated'
};

export enum URL_QUERY_PARAMETERS {
  PERMALINK = 'permalink',
  HOST = 'host'
}

export enum INTEGRATION_FIELD_TYPES {
  TEXT = 'text',
  TEXT_LARGE = 'text_large',
  PASSWORD = 'password',
  MULTISELECT = 'multiselect',
  JSON = 'json',
  BOOLEAN = 'boolean',
  DATE = 'date',
  ARRAY = 'array',
  NUMBER = 'number',
  SINGLESELECT = 'singleselect',
  EMAIL = 'email',
  DATETIME = 'datetime',
  TIME = 'time',
  BUTTON = 'button'
}

export enum T_AND_E_METADATA_FIELD_TYPES {
  number = 'number',
  text = 'text',
  boolean = 'boolean'
}

export enum INTEGRATION_STATUS {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled'
}

export enum INTEGRATION_REACT_HOOK_FORM_FIELDS {
  INTEGRATION_STATUS = 'integration-status',
  TEST_CONNECTION = 'test_connection'
}

export enum INPUT_ERROR_TYPES {
  LOCKOUT = 'lockout'
}

export enum RULES_STATUS {
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
  UNPUBLISHED = 'Unpublished',
  PUBLISHING = 'Publishing',
  UNPUBLISHING = 'Unpublishing',
  ALL = 'All'
}

export enum ENGINE_STATUS {
  SUCCESS = 'success',
  FAILED = 'failed',
  PROCESSING = 'processing'
}

export enum RULES_ACTIONS {
  ADD = 'Add',
  VIEW = 'View',
  EDIT = 'Edit',
  DUPLICATE = 'Duplicate',
  PUBLISH = 'Publish',
  UNPUBLISH = 'Unpublish',
  DELETE = 'Delete',
  PUBLISH_UPDATE = 'Publish update'
}

export enum RULES_ENDPOINTS {
  PUBLISH_RULES = 'PUBLISH_RULES',
  UNPUBLISH_RULES = 'UNPUBLISH_RULES',
  DELETE_RULES = 'DELETE_RULES'
}

export enum STATUS_CODES {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  LOCKED = 423,
  INTERNAL_SERVER_ERROR = 500
}

export enum GET_COLUMNS_FILTER_ENUM {
  IS_READ_ONLY = 'is_readonly',
  IS_SEARCHABLE = 'is_searchable'
}

// TODO - re-enable for metadata to return columns from gradable_activity_dimension_embellishments_export
export const FIELD_MAPPINGS = [
  {
    name: 'activity_name',
    label: 'Activity name',
    width: 400,
    dataType: T_AND_E_METADATA_FIELD_TYPES.text
  },
  {
    name: 'gradesheet_name',
    label: 'Gradesheet',
    width: 300,
    dataType: T_AND_E_METADATA_FIELD_TYPES.text
  },
  {
    name: 'grade_type',
    label: 'Grade type',
    dataType: T_AND_E_METADATA_FIELD_TYPES.text
  },
  {
    name: 'source',
    label: 'Source system',
    dataType: T_AND_E_METADATA_FIELD_TYPES.text
  },
  {
    name: 'source_type',
    label: 'Source type',
    dataType: T_AND_E_METADATA_FIELD_TYPES.text
  },
  {
    name: 'source_id',
    label: 'Source id',
    dataType: T_AND_E_METADATA_FIELD_TYPES.text
  },
  {
    name: 'order_number',
    label: 'Order number',
    dataType: T_AND_E_METADATA_FIELD_TYPES.number
  },
  {
    name: 'points_possible',
    label: 'Points possible',
    dataType: T_AND_E_METADATA_FIELD_TYPES.number
  },
  {
    name: 'points_pass_threshold',
    label: 'Points pass threshold',
    dataType: T_AND_E_METADATA_FIELD_TYPES.number
  },
  {
    name: 'points_low_performance_threshold',
    label: 'Points low performance threshold',
    dataType: T_AND_E_METADATA_FIELD_TYPES.number
  },
  {
    name: 'private_field',
    label: 'Private field',
    dataType: T_AND_E_METADATA_FIELD_TYPES.boolean
  },
  { name: 'assessment_type', label: 'Assessment type', dataType: 'category' },
  {
    name: 'is_aggregate',
    label: 'Is aggregate?',
    dataType: T_AND_E_METADATA_FIELD_TYPES.boolean
  },
  {
    name: 'is_final',
    label: 'Is final?',
    dataType: T_AND_E_METADATA_FIELD_TYPES.boolean
  },
  { name: 'tag', label: 'Tag', dataType: T_AND_E_METADATA_FIELD_TYPES.text },
  {
    name: 'tag_collection',
    label: 'Tag Collection',
    dataType: T_AND_E_METADATA_FIELD_TYPES.text
  },
  {
    name: 'academic_year',
    label: 'Academic year',
    dataType: T_AND_E_METADATA_FIELD_TYPES.text
  },
  {
    name: 'number_of_grades',
    label: '# of Grades',
    dataType: T_AND_E_METADATA_FIELD_TYPES.number
  },
  {
    name: 'used_in_learning_events',
    label: 'Course code',
    dataType: T_AND_E_METADATA_FIELD_TYPES.text
  },
  {
    name: 'gradable_activity_key',
    label: 'Gradable Activity Key',
    dataType: T_AND_E_METADATA_FIELD_TYPES.number
  }
];

export const LANGUAGE = {
  ENGLISH: { code: 'en', label: 'English', value: 'english' },
  FRENCH: { code: 'fr', label: 'Français', value: 'french' }
};

export enum TAG {
  IGNORE = 'ignore'
}

export const OID_FORMAT = '6ba7b812-9dad-11d1-80b4-00c04fd430c8'; // oid format -> https://developer.hashicorp.com/terraform/language/functions/uuidv5';

export enum EDIT_USER_VIEWS {
  ACCOUNT_DETAILS = 'account-details'
}

export enum EDIT_USER_SEARCH {
  VIEW = 'view',
  PRODUCT = 'product'
}

export enum VALIDATION_ERRORS {
  INVALID = 'invalid',
  REQUIRED = 'required',
  DUPLICATE = 'duplicate'
}

export enum NAVIGATION_ACTIONS {
  PUSH = 'PUSH'
}

export enum GRANULAR_FILTER_SECTIONS {
  LEARNER = 'learner',
  LEARNING_EVENT = 'learning_event'
}
