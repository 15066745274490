const white = '#FFFFFF';
const black = '#000000';

const altusPurple = {
  lightest: '#EDEEF6',
  lighter: '#DBDDED',
  light: '#7077B7',
  default: '#4C55A5',
  dark: '#3D4484',
  darker: '#2E3363',
  darkest: '#080810'
};

const altusTeal = {
  lightest: '#EAF8F7',
  lighter: '#CCEDEB',
  light: '#4CC0B8',
  default: '#00A59B',
  dark: '#00847B',
  darker: '#00635C',
  darkest: '#00100F'
};

const red = {
  lightest: '#FAE8EE',
  lighter: '#FFB19E',
  light: '#DF5D85',
  default: '#BA1E50',
  dark: '#921139',
  darker: '#53081F',
  darkest: '#150208'
};

const green = {
  lightest: '#EDF9E8',
  lighter: '#DCF3D1',
  light: '#86D55E',
  default: '#52C41A',
  dark: '#429C14',
  darker: '#31750F',
  darkest: '#081302'
};

const grey = {
  dashboard: '#E5E5E5',
  lightest: '#FFFFFF',
  lighter: '#F5F5F5',
  light: '#C6C6C6',
  default: '#8C8C8C',
  dark: '#707070',
  darker: '#545454',
  darkest: '#080810'
};

const orange = {
  lightest: '#FEF1EC',
  lighter: '#F9B79F',
  light: '#F58C65',
  default: '#F26F3E',
  dark: '#C25932',
  darker: '#612C19',
  darkest: '#180B06'
};

const yellow = {
  default: '#FFF5B1',
  light: '#FEFBE0',
  dark: '#E0CA01',
  darkest: '#A18F33'
};

//@takecasper/acuity-design-system
const designSystem = {
  neutralDark: '#555555'
};

const muiDefaults = {
  checkedHover: '#E7EFF9',
  checked: '#EFF5FC'
};

const primary = {
  ...altusPurple
};

const secondary = {
  ...altusTeal
};

const gradient = `linear-gradient(180deg, ${secondary.default} -7.43%, ${secondary.default} -7.43%, #21829F 53.87%, ${primary.default} 100%);`;

const outline = {
  lightest: 'transparent',
  lighter: grey.lighter,
  light: grey.light,
  default: white,
  dark: grey.default,
  darker: 'transparent',
  darkest: 'transparent'
};

const danger = {
  ...red
};

const success = {
  ...green
};

const alert = {
  ...orange
};

export default {
  black,
  white,
  primary,
  secondary,
  outline,
  danger,
  success,
  alert,
  gradient,
  grey,
  base: {
    altusPurple,
    altusTeal,
    orange,
    // This key value name is dependant on the name used in
    // the db for duetTheme colors, so it should not be changed
    // unless the duetThemes have been updated
    connectRed: red,
    yellow
  },
  designSystem,
  muiDefaults
};
