import colors from './colors';
import spacing from './spacing';

const itemDefault = {
  height: 'auto',
  outline: 'none',
  padding: `${spacing?.(1.5)} ${spacing?.(2.5)}`,
  active: {
    backgroundColor: {
      disabled: 'transparent',
      default: colors.primary?.lighter
    },
    borderColor: 'transparent'
  },
  focus: {
    borderColor: colors.primary?.default
  }
};

const item = {
  ...itemDefault,
  color: {
    disabled: colors.grey?.light,
    default: colors.primary?.darkest
  },
  hover: {
    backgroundColor: {
      disabled: 'transparent',
      default: colors.outline?.lighter
    }
  },
  active: {
    backgroundColor: {
      disabled: 'transparent',
      default: colors.primary?.lightest
    }
  },
  focus: {
    backgroundColor: {
      disabled: 'transparent',
      default: colors.primary?.lightest
    }
  }
};

// DO NOT update this value unless regression testing all instances.
const filterPaneWidth = '290px';

export default {
  item,
  filterPaneWidth
};
