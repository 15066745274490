export default {
  general_permissions_tab_subtitle:
    'Cette section décrit les autorisations générales du système pour cet utilisateur dans ce programme',
  duet_permissions_tab_subtitle:
    'Cette section décrit les autorisations liées à Duet',
  snapshot_permissions_tab_subtitle:
    'Cette section décrit les autorisations liées à Snapshot',
  casper_permissions_tab_subtitle:
    'Cette section décrit les autorisations liées à Casper',
  analytics_permissions_tab_subtitle:
    'Voici les types de contenu que cet utilisateur peut visualiser dans Analytics',
  permissions_tab_data_types: 'Types de données',
  permissions_tab_data_filters: 'Filtres de données',
  permissions_tab_data_filters_subtitle:
    'Cette section décrit les sous-ensembles de données auxquels un utilisateur peut accéder',
  permissions_tab_data_filters_no_access: 'Aucun accès',
  permissions_tab_data_filters_no_access_description:
    "Cet utilisateur ne peut voir aucun dossier. Tout tableau de bord auquel cet utilisateur a été autorisé à accéder (via les types de données) ne sera pas rempli de dossiers pour le moment. L'accès aux données doit être défini pour permettre à cet utilisateur de consulter les dossiers.",
  permissions_tab_data_filters_unrestricted_access: 'Accès illimité',
  permissions_tab_data_filters_unrestricted_access_description:
    'Cet utilisateur peut voir tous les dossiers. Tout tableau de bord auquel cet utilisateur a accès (via les types de données) sera entièrement rempli de tous les dossiers disponibles. Tout nouvel dossier sera automatiquement rendu accessible à cet utilisateur.',
  analytics_help_text:
    'Besoin d`aide ? En savoir plus sur les types de données et les filtres',
  save_general_permissions: 'Enregistrer les autorisations générales',
  save_duet_permissions: 'Enregistrer les autorisations Duet',
  save_snapshot_permissions: 'Enregistrer les autorisations Snapshot',
  save_casper_permissions: 'Enregistrer les autorisations Casper',
  save_analytics_permissions: 'Enregistrer les autorisations Analytics',
  general_permissions_title: 'Autorisations générales',
  duet_permissions_title: 'Autorisations Duet',
  snapshot_permissions_title: 'Autorisations Snapshot',
  casper_permissions_title: 'Autorisations Casper',
  analytics_permissions_title: 'Autorisations Analytics',
  feature_category_title: 'Fonctionnalités',
  data_category_title: 'Types de données',
  data_category_subtitle_analytics:
    'Voici les types de contenu que cet utilisateur peut visualiser dans Analytics.',
  navigation_confirmation_title: 'Vous avez des modifications non sauvegardées',
  navigation_confirmation_description_line_1:
    "Si vous quittez le site ou changez d'onglet, vos modifications seront perdues.",
  navigation_confirmation_description_line_2:
    'Voulez-vous rester et continuer à éditer, ou quitter sans sauvegarder ?',
  navigation_confirmation_stay: 'Rester',
  navigation_confirmation_leave_without_saving: 'Quitter sans sauvegarder',
  save_permissions_duration:
    "Les mises à jour des autorisations peuvent prendre jusqu'à 15 minutes pour être reflétées dans l'ensemble du système.",
  save_permissions_success_toast_message:
    'Vos modifications ont été sauvegardées.',
  save_permissions_error_toast_message:
    "Quelque chose a mal tourné. Vos modifications n'ont pas pu être sauvegardées. Veuillez réessayer.",
  no_program_permissions:
    "Cet utilisateur n'a aucune autorisation attribuée à ce programme. Attribuer des autorisations pour que l'utilisateur puisse accéder à ce programme.",
  program_permissions: 'Autorisations du programme',
  unrestricted_access_summary:
    '<b>{{name}}</b> a un <b>accès complet</b> aux dossiers appartenant aux <b>types de données pour lesquels les autorisations ont été accordées</b> ci-dessus. Cet utilisateur peut consulter les dossiers pour les types de données auxquels il est autorisé pour :',
  unrestricted_access_summary_description:
    "TOUS <b>les Apprenant(e)s</b><br />ET<br />TOUS <b>les Activités d'apprentissage</b>",
  data_filter_description:
    "Les filtres suivants sont utilisés pour définir l'accès aux données granulaires pour <b>{{name}}</b>. Lorsque des options de filtre sont sélectionnées, le résumé du filtre de données à gauche reflétera la façon dont ces filtres fonctionnent ensemble. Pour accorder à cet utilisateur un accès illimité, décochez les options ci-dessous.",
  filter_data_by_learner:
    "Filtrer l'accès aux données par attributs « apprenant(e) »",
  filter_data_by_learning_events:
    "Filtrer l'accès aux données par attributs « Activité d'apprentissage »",
  manage_data_filters: 'Gestion des filtres',
  restricted_access_summary:
    "<b>{{name}}</b> n'a <b>pas accès</b> aux dossiers appartenant aux <b>types de données pour lesquels les autorisations ont été accordées</b> ci-dessus. Cet utilisateur ne peut consulter <b>aucune</b> donnée. Retirez ou sélectionnez les filtres à droite pour accorder un accès complet ou limité.",
  limited_access_summary:
    '<b>{{name}}</b> a un <b>accès limité</b> aux dossiers appartenant aux <b>types de données pour lesquels les autorisations ont été accordées</b> ci-dessus. Cet utilisateur peut consulter les dossiers pour les types de données auxquels il est autorisé lorsque :',
  filter_access_warning:
    "Lors de l'enregistrement des autorisations Analytics, cette section de filtrage des données sera désactivée car il n'y a pas de sélection de filtre en cours.",
  confirm_uncheck_filter_title: 'Confirmer',
  confirm_uncheck_filter_body:
    'Êtes-vous sûr de vouloir décocher cette section ? Cette action effacera tous les attributs « {{filterType}} ».',
  filter_type_learner: 'Apprenant',
  filter_type_learning_event: "Activité d'apprentissage"
};
