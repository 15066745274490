export default {
  common_network_error: `Une erreur s'est produite, veuillez essayer plus tard ou rafraîchir la page.`,
  common_2fa_method_text_message: 'Message texte',
  common_2fa_method_email: 'Courriel',
  common_2fa_method_phone_call: 'Appel téléphonique',
  common_steps: 'Étape {{val1}} sur {{val2}}',
  common_verification_code_message:
    'Je veux recevoir mon code de vérification par :',
  common_verification_code_question: `Vous n'avez pas reçu de code ?`,
  common_verification_code_resend: 'Renvoyer le code de vérification',
  common_verification_code_email:
    'Téléphone non disponible ? Recevez votre code de vérification par courriel',
  common_copyright: 'Droits d’auteur',
  common_no_results_title: 'Vos résultats ne sont pas encore arrivés.',
  common_no_results_subtitle: `Les résultats peuvent prendre un peu de temps avant d'apparaître.`,
  common_no_data_title: 'Pas de données disponibles',
  common_no_data_subtitle: `Nous n'avons pas trouvé de résultats correspondant à la combinaison sélectionnée, veuillez essayer avec un nombre et/ou un filtre différent.`,
  common_role_program_admin: 'Admin programme',
  common_role_team_member: `Membre de l'équipe`,
  common_no_product_title: `Oups ! Il n'y a rien à afficher ici.`,
  common_no_product_subtitle: `Il semblerait que vous n'ayez pas encore accès à Snapshot et/ou à Duet. Veuillez contacter l'administrateur(-trice) du programme ou votre responsable de la réussite client chez Acuity pour vous permettre d'y accéder.`,
  common_permissions: 'Autorisations',
  common_or: 'ou',
  common_on: 'Activé',
  common_off: 'Desactivé',
  common_true: 'Oui',
  common_false: 'Non',
  common_search: 'Rechercher',
  common_general: 'Générale',
  common_duet: 'Duet',
  common_snapshot: 'Snapshot',
  common_casper: 'Casper',
  common_analytics: 'Analytics',
  common_profile: 'Profil',
  common_something_went_wrong: "Une erreur s'est produite de notre côté",
  common_please_try_again:
    "Veuillez réessayer plus tard. Si le problème persiste, contactez notre équipe de soutien pour obtenir de l'aide",
  common_mfa: 'AFM',
  common_terms_and_conditions: 'Conditions générales',
  common_status: 'Statut',
  common_sms: 'SMS',
  common_email: 'Courriel',
  common_call: 'Appel téléphonique',
  common_none: 'Aucun',
  common_pending: 'En attente',
  common_active: 'Actif',
  common_inactive: 'Inactif',
  common_form_validation_error_title: 'Il y a des erreurs dans le formulaire',
  common_form_validation_error_description:
    'Veuillez vérifier les erreurs suivantes :',
  common_success: 'Réussi',
  common_error: 'Erreur',
  common_warning: 'Avertissement !',
  common_action_needed: 'Action requise',
  common_summary: 'Résumé'
};
