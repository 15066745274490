import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { devices } from 'shared/css/devices';

export default createGlobalStyle`
  ${normalize}
  
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: unset !important; // !important is used to override Qlik server stylesheets
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: 'Lato', 'Roboto', sans-serif !important;  // !important is used to override Qlik server stylesheets
    font-size: 0.875rem;
    line-height: 1.6;
    margin: 0;
    overflow-x: hidden;
    // Needed to prevent Qlik from displaying duplicate selection toolbar popover in Manage User mashups.
    #qv-selection-toolbar-popover {
      display: none !important;
    }
  }
  
  input, button {
    font-family: 'Lato', 'Roboto', sans-serif;
  }

  ul, li, ol {
    margin: 0;
    padding: 0;
  }

  ul > li {
    list-style: none;
  }

  button {
    cursor: pointer;
    outline: none;
  }

  input {
    border: none;
  }

  h1, h2, h3, h4, p {
    margin: 0;
    padding: 0;
  }

  #root {
    background: #ffffff;
    min-height: 100vh;
    --toastify-toast-width: 90%;
    @media ${devices.tablet} {
      --toastify-toast-width: 400px;
    }
  }

  strong {
    font-weight: 700;
  }
`;
