export default {
  common_network_error:
    'Something went wrong, please try again later or refresh the page.',
  common_2fa_method_text_message: 'SMS Text message',
  common_2fa_method_email: 'Email',
  common_2fa_method_phone_call: 'Phone call',
  common_steps: 'Step {{val1}} of {{val2}}',
  common_verification_code_message: 'Receive your verification code by:',
  common_verification_code_question: `Didn't receive a code?`,
  common_verification_code_resend: 'Re-send verification code',
  common_verification_code_email:
    'Phone unavailable? Send verification code via email',
  common_try_another_way: 'Try another way',
  common_copyright: 'Copyright',
  common_no_results_title: `Your results haven't arrived yet.`,
  common_no_results_subtitle:
    'It may take some time for the results to appear.',
  common_no_data_title: 'No data available to display',
  common_no_data_subtitle:
    'We cannot find any results based on the combination you have selected, please try a different number and/or filter selection.',
  common_role_program_admin: 'Program Admin',
  common_role_team_member: 'Team member',
  common_no_product_title: 'Uh oh! Nothing to display here.',
  common_no_product_subtitle:
    'It looks like you don’t yet have access to Snapshot or Duet. Please contact your program administrator or your Customer Success Manager to enable access.',
  common_permissions: 'Permissions',
  common_or: 'or',
  common_on: 'On',
  common_off: 'Off',
  common_true: 'True',
  common_false: 'False',
  common_search: 'Search',
  common_general: 'General',
  common_duet: 'Duet',
  common_snapshot: 'Snapshot',
  common_casper: 'Casper',
  common_analytics: 'Analytics',
  common_profile: 'Profile',
  common_something_went_wrong: 'Something went wrong on our end',
  common_please_try_again:
    'Please try again later. If the problem persists, contact our support team for help',
  common_mfa: 'MFA',
  common_terms_and_conditions: 'Terms & Conditions',
  common_status: 'Status',
  common_sms: 'SMS',
  common_email: 'Email',
  common_call: 'Call',
  common_none: 'None',
  common_pending: 'Pending',
  common_active: 'Active',
  common_inactive: 'Inactive',
  common_form_validation_error_title: 'There are errors in the form',
  common_form_validation_error_description:
    'Please review the following errors:',
  common_success: 'Success',
  common_error: 'Error',
  common_warning: 'Warning!',
  common_action_needed: 'Action needed',
  common_summary: 'Summary'
};
